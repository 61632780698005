import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import store from "../store";
import middlewarePipeline from "./middleware-pipeline";
import generalRoutes from "./routes/general";
import authRoutes from "./routes/auth";
import managerRoutes from "./routes/manager";
import accountantRoutes from "./routes/accountant";
import adminRoutes from "./routes/admin";

const NotFound = () => import("@/views/NotFound");

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...generalRoutes,
    ...authRoutes,
    ...managerRoutes,
    ...accountantRoutes,
    ...adminRoutes,
    {
      path: "*",
      name: "not-found",
      component: NotFound
    }
  ],
  scrollBehavior(to) {
    if (to.meta.disableScrollBehavior && to.hash) {
      return {};
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  if (store.state.firstLoad) {
    await store.dispatch("auth/me");
    await store.dispatch("cart/getCart");
    await store.dispatch("setFirstLoad", false);
  }

  if (store.state.showGeneralSidebar) {
    store.dispatch("toggleGeneralSidebar", false);
  }
  if (store.state.showCart) {
    store.dispatch("cart/toggleCart", false);
  }

  const middlewares = to.matched
    .map(route => route.meta.middleware)
    .flat()
    .filter(el => el !== undefined);

  if (middlewares.length === 0) {
    return next();
  }

  const context = { to, from, next, store };

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1)
  });
});

export default router;
