export default function auth({ to, next, store }) {
  if (!store.state.auth.isAuthenticated) {
    return next({ name: "login", query: { from: to.path } });
  }
  if (store.state.auth.user.phone_verified_at === null) {
    return next({ name: "verify", query: { from: to.path } });
  }

  return next();
}
