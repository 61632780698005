import Vue from "vue";
import {
  CButton,
  CCardBody,
  CDataTable,
  CFooter,
  CLink,
  CSidebar,
  CSidebarBrand,
  CIcon,
  CRenderFunction,
  CWrapper,
  CHeader,
  CToggler,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumb,
  CContainer,
  CCard,
  CCardHeader,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavDropdown,
  CSpinner,
  CRow,
  CCol,
  CInput,
  CSelect,
  CInputFile,
  CInputCheckbox,
  CAlert,
  CTabs,
  CTab,
  CCollapse,
  CInputRadioGroup,
  CPagination,
  CTextarea,
  CElementCover,
  CSwitch,
  CModal
} from "@coreui/vue/src";

Vue.component("CButton", CButton);
Vue.component("CCardBody", CCardBody);
Vue.component("CDataTable", CDataTable);
Vue.component("CFooter", CFooter);
Vue.component("CLink", CLink);
Vue.component("CSidebar", CSidebar);
Vue.component("CSidebarBrand", CSidebarBrand);
Vue.component("CIcon", CIcon);
Vue.component("CRenderFunction", CRenderFunction);
Vue.component("CWrapper", CWrapper);
Vue.component("CHeader", CHeader);
Vue.component("CToggler", CToggler);
Vue.component("CHeaderNav", CHeaderNav);
Vue.component("CHeaderNavItem", CHeaderNavItem);
Vue.component("CHeaderNavLink", CHeaderNavLink);
Vue.component("CSubheader", CSubheader);
Vue.component("CBreadcrumb", CBreadcrumb);
Vue.component("CContainer", CContainer);
Vue.component("CCard", CCard);
Vue.component("CCardHeader", CCardHeader);
Vue.component("CSidebarNav", CSidebarNav);
Vue.component("CSidebarNavItem", CSidebarNavItem);
Vue.component("CSidebarNavDropdown", CSidebarNavDropdown);
Vue.component("CSpinner", CSpinner);
Vue.component("CRow", CRow);
Vue.component("CCol", CCol);
Vue.component("CInput", CInput);
Vue.component("CSelect", CSelect);
Vue.component("CInputFile", CInputFile);
Vue.component("CInputCheckbox", CInputCheckbox);
Vue.component("CAlert", CAlert);
Vue.component("CTabs", CTabs);
Vue.component("CTab", CTab);
Vue.component("CCollapse", CCollapse);
Vue.component("CInputRadioGroup", CInputRadioGroup);
Vue.component("CPagination", CPagination);
Vue.component("CTextarea", CTextarea);
Vue.component("CElementCover", CElementCover);
Vue.component("CSwitch", CSwitch);
Vue.component("CModal", CModal);
