import {
  cilBan,
  cilBarChart,
  cilBasket,
  cilBell,
  cilCart,
  cilCheckAlt,
  cilClock,
  cilCog,
  cilEnvelopeClosed,
  cilFlagAlt,
  cilGroup,
  cilInbox,
  cilLibrary,
  cilLibraryAdd,
  cilNotes,
  cilOpacity,
  cilPencil,
  cilReload,
  cilSend,
  cilSpreadsheet,
  cilStar,
  cilTransfer,
  cilTrash,
  cilTruck,
  cilWalk,
  cilXCircle
} from "@coreui/icons";

export default {
  cilBasket,
  cilGroup,
  cilCart,
  cilTrash,
  cilPencil,
  cilLibrary,
  cilOpacity,
  cilSend,
  cilTruck,
  cilWalk,
  cilCheckAlt,
  cilSpreadsheet,
  cilFlagAlt,
  cilXCircle,
  cilBarChart,
  cilEnvelopeClosed,
  cilTransfer,
  cilBan,
  cilCog,
  cilStar,
  cilInbox,
  cilBell,
  cilReload,
  cilClock,
  cilLibraryAdd,
  cilNotes
};
