import Vue from "vue";
import { SET_CART, SET_IS_EXTRA_SHOP_ENABLED } from "../mutation-types";
import debounce from "lodash.debounce";

export default {
  namespaced: true,

  state: {
    cart: [],
    is_extra_shop_enabled: false
  },

  getters: {
    itemsCount: state => {
      return state.cart.items.reduce(
        (accumulator, item) => accumulator + item.quantity,
        0
      );
    },
    isPickup: state => {
      return state.cart.is_pickup;
    }
  },

  mutations: {
    [SET_CART](state, value) {
      state.cart = value;
    },
    [SET_IS_EXTRA_SHOP_ENABLED](state, value) {
      state.is_extra_shop_enabled = value;
    }
  },

  actions: {
    getCart({ commit }) {
      return Vue.axios
        .get("/api/cart")
        .then(response => {
          commit(SET_CART, response.data.data);
          commit(
            SET_IS_EXTRA_SHOP_ENABLED,
            response.data.meta.is_extra_shop_enabled
          );
        })
        .catch(() => {
          commit(SET_CART, []);
          commit(SET_IS_EXTRA_SHOP_ENABLED, false);
        });
    },
    async deleteCart({ dispatch }) {
      await Vue.axios.delete("/api/cart");

      return dispatch("getCart");
    },
    async updateBalance({ dispatch }, id) {
      await Vue.axios.post("/api/user/balance/", { order_id: id });

      return dispatch("getCart");
    },
    async addItem({ dispatch }, data) {
      await Vue.axios.post("/api/cart-items", data);

      return dispatch("getCart");
    },
    removeItem({ dispatch }, id) {
      Vue.axios
        .delete(`/api/cart-items/${id}`)
        .finally(() => dispatch("getCart"));
    },
    changeItemQuantity({ state, dispatch }, { id, increment = true }) {
      const itemIndex = state.cart.items.findIndex(item => item.id === id);
      const item = state.cart.items[itemIndex];

      if (!increment && item.quantity - 1 < 1) return;
      if (increment && item.quantity + 1 > item.dish.per_order) return;
      if (increment && item.quantity + 1 > item.dish.stock) return;

      increment ? item.quantity++ : item.quantity--;
      dispatch("updateItemQuantity", { id, quantity: item.quantity });
    },
    setIsPickup({ dispatch }, value) {
      return Vue.axios
        .patch(`/api/cart`, { is_pickup: value })
        .finally(() => dispatch("getCart"));
    },
    setUseBalance({ dispatch }, { balance, is_bal }) {
      return Vue.axios
        .patch(`/api/cart`, { balance: balance, is_balance: is_bal })
        .finally(() => {
          dispatch("getCart");
        });
    },
    setTips({ dispatch }, { tips }) {
      return Vue.axios.patch(`/api/cart`, { tips: tips }).finally(() => {
        dispatch("getCart");
      });
    },
    setDeliverTo({ dispatch }, value) {
      return Vue.axios
        .patch(`/api/cart`, { deliver_to: value })
        .finally(() => dispatch("getCart"));
    },
    updateItemQuantity: debounce(function({ dispatch }, { id, quantity }) {
      Vue.axios.patch(`/api/cart-items/${id}`, { quantity }).finally(() => {
        dispatch("getCart");
      });
    }, 500)
  }
};
