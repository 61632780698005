import Vue from "vue";
import { SET_IS_AUTHENTICATED, SET_USER } from "../mutation-types";

export default {
  namespaced: true,

  state: {
    isAuthenticated: false,
    user: null
  },

  mutations: {
    [SET_IS_AUTHENTICATED](state, value) {
      state.isAuthenticated = value;
    },
    [SET_USER](state, value) {
      state.user = value;
    }
  },

  actions: {
    async signUp({ dispatch }, credentials) {
      await Vue.axios.get("/sanctum/csrf-cookie");
      await Vue.axios.post("/auth/register", credentials);

      return dispatch("me");
    },

    async signIn({ dispatch }, credentials) {
      await Vue.axios.get("/sanctum/csrf-cookie");
      await Vue.axios.post("/auth/login", credentials);

      return dispatch("me");
    },

    async verifyCode({ dispatch }, credentials) {
      await Vue.axios.get("/sanctum/csrf-cookie");
      await Vue.axios.post("/auth/phone/verify", credentials);

      return dispatch("me");
    },

    async resendCode() {
      await Vue.axios.get("/sanctum/csrf-cookie");
      await Vue.axios.post("/auth/phone/resend");
    },

    async forgotPassword(context, email) {
      await Vue.axios.get("/sanctum/csrf-cookie");

      return Vue.axios.post("/auth/password/email", { email });
    },

    async resetPassword({ dispatch }, payload) {
      await Vue.axios.get("/sanctum/csrf-cookie");
      await Vue.axios.post("/auth/password/reset", payload);

      return dispatch("me");
    },

    async signOut({ dispatch }) {
      await Vue.axios.post("/auth/logout");

      return dispatch("me");
    },

    me({ commit }) {
      return Vue.axios
        .get("/api/user")
        .then(response => {
          commit(SET_IS_AUTHENTICATED, true);
          commit(SET_USER, response.data.data);
        })
        .catch(() => {
          commit(SET_IS_AUTHENTICATED, false);
          commit(SET_USER, null);
        });
    }
  }
};
