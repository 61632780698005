import auth from "@/middlewares/auth";
import cartHasItems from "@/middlewares/cart-has-items";

const MainLayout = () => import("@/views/layouts/Main");

const Home = () => import("@/views/Home");
const News = () => import("@/views/News");
const ContactUs = () => import("@/views/ContactUs");
const Cabinet = () => import("@/views/Cabinet");
const Favourites = () => import("@/views/Favourites");
const Restaurant = () =>
  import(/* webpackChunkName: "restaurants" */ "@/views/general/Restaurant");
const Cart = () =>
  import(/* webpackChunkName: "cart" */ "@/views/general/Cart");
const Order = () =>
  import(/* webpackChunkName: "orders" */ "@/views/general/Order");
const OrderPayment = () =>
  import(/* webpackChunkName: "orders" */ "@/views/general/order/Payment");

export default [
  {
    path: "",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "news",
        name: "news",
        component: News
      },
      {
        path: "contact-us",
        name: "contact_us",
        component: ContactUs
      },
      {
        path: "cabinet",
        name: "cabinet",
        component: Cabinet,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "favourites",
        name: "favourites",
        component: Favourites,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "restaurants/:restaurant",
        name: "restaurant",
        component: Restaurant,
        meta: {
          disableScrollBehavior: true
        }
      },
      {
        path: "cart",
        name: "cart",
        component: Cart,
        meta: {
          middleware: [auth, cartHasItems]
        }
      },
      {
        path: "orders/:order",
        name: "order",
        component: Order,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "orders/:order/payment",
        name: "order.payment",
        component: OrderPayment,
        meta: {
          middleware: [auth]
        }
      }
    ]
  }
];
