export default function hideVerified({ next, store }) {
  if (
    store.state.auth.isAuthenticated &&
    store.state.auth.user.phone_verified_at !== null
  ) {
    return next({ name: "home" });
  }

  return next();
}
