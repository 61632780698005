import hideAuth from "@/middlewares/hide-auth";
import hideVerified from "@/middlewares/hide-verified";

const MainLayout = () => import("@/views/layouts/Main");

const Login = () => import(/* webpackChunkName: "auth" */ "@/views/auth/Login");
const Register = () =>
  import(/* webpackChunkName: "auth" */ "@/views/auth/Register");
const PasswordReset = () =>
  import(/* webpackChunkName: "auth" */ "@/views/auth/PasswordReset");
const Verification = () =>
  import(/* webpackChunkName: "auth" */ "@/views/auth/Verification");

export default [
  {
    path: "",
    component: MainLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
        meta: {
          middleware: [hideAuth]
        }
      },
      {
        path: "register",
        name: "register",
        component: Register,
        meta: {
          middleware: [hideAuth]
        }
      },
      {
        path: "password/reset/:token",
        name: "password-reset",
        component: PasswordReset,
        meta: {
          middleware: [hideAuth]
        }
      },
      {
        path: "verify",
        name: "verify",
        component: Verification,
        meta: {
          middleware: [hideVerified]
        }
      }
    ]
  }
];
