import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/cookies";
import i18n from "./plugins/i18n";
import "./plugins/axios";
import "./plugins/gmap";
import "./plugins/calendar";
import "./plugins/coreui";
import "./plugins/gtag";
import icons from "./assets/admin/icons";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  icons,

  render: function(h) {
    return h(App);
  }
}).$mount("#app");
