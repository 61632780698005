import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import cart from "./modules/cart";
import {
  SET_FIRST_LOAD,
  SET_HERO,
  SET_SLIDERS,
  TOGGLE_ADMIN_SIDEBAR,
  TOGGLE_CART,
  TOGGLE_GENERAL_SIDEBAR
} from "./mutation-types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firstLoad: true,
    showGeneralSidebar: false,
    showCart: false,
    showAdminSidebar: "responsive",
    hero: {},
    sliders: []
  },
  mutations: {
    [SET_FIRST_LOAD](state, value) {
      state.firstLoad = value;
    },
    [TOGGLE_GENERAL_SIDEBAR](state, value) {
      state.showGeneralSidebar = value;
    },
    [TOGGLE_CART](state, value) {
      state.showCart = value;
    },
    [TOGGLE_ADMIN_SIDEBAR](state) {
      const sidebarClosed = [false, "responsive"].includes(
        state.showAdminSidebar
      );
      state.showAdminSidebar = sidebarClosed ? true : "responsive";
    },
    [SET_HERO](state, value) {
      state.hero = value;
    },
    [SET_SLIDERS](state, value) {
      state.sliders = value;
    }
  },
  actions: {
    setFirstLoad({ commit }, value) {
      commit(SET_FIRST_LOAD, value);
    },
    toggleGeneralSidebar({ commit }, value) {
      commit(TOGGLE_GENERAL_SIDEBAR, value);
    },
    toggleCart({ commit }, value) {
      commit(TOGGLE_CART, value);
    },
    toggleAdminSidebar({ commit }) {
      commit(TOGGLE_ADMIN_SIDEBAR);
    },
    setHero({ commit }, value) {
      commit(SET_HERO, value);
    },
    setSliders({ commit }, value) {
      commit(SET_SLIDERS, value);
    }
  },
  modules: {
    auth,
    cart
  }
});
