export default function cartHasItems({ to, next, store }) {
  if (!store.state.auth.isAuthenticated) {
    return next({ name: "login", query: { from: to.path } });
  }
  if (store.getters["cart/itemsCount"] === 0) {
    return next({ name: "home" });
  }

  return next();
}
