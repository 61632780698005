// index
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";
export const TOGGLE_GENERAL_SIDEBAR = "TOGGLE_GENERAL_SIDEBAR";
export const TOGGLE_CART = "TOGGLE_CART";
export const TOGGLE_ADMIN_SIDEBAR = "TOGGLE_ADMIN_SIDEBAR";
export const SET_HERO = "SET_HERO";
export const SET_SLIDERS = "SET_SLIDERS";

// auth
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const SET_USER = "SET_USER";

// cart
export const SET_CART = "SET_CART";
export const SET_IS_EXTRA_SHOP_ENABLED = "SET_IS_EXTRA_SHOP_ENABLED";
