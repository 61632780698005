export default function isAdmin({ next, store }) {
  if (
    store.state.auth.isAuthenticated &&
    store.state.auth.user.roles.some(role => role.name === "admin")
  ) {
    return next();
  }

  return next({ name: "home" });
}
