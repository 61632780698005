import isManager from "@/middlewares/is-manager";

const ManagerLayout = () => import("@/views/layouts/Manager");

const NewOrders = () =>
  import(/* webpackChunkName: "manager-orders" */ "@/views/manager/orders/New");
const ConfirmedOrders = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Confirmed"
  );
const InDelivery = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/InDelivery"
  );
const Assigned = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Assigned"
  );
const Preorders = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Preorders"
  );
const Finished = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Finished"
  );
const Canceled = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Canceled"
  );
const Pickup = () =>
  import(
    /* webpackChunkName: "manager-orders" */ "@/views/manager/orders/Pickup"
  );
const Map = () =>
  import(/* webpackChunkName: "manager-other" */ "@/views/manager/Map");

export default [
  {
    path: "/manager",
    component: ManagerLayout,
    redirect: { name: "manager.orders.new" },
    meta: {
      middleware: [isManager]
    },
    children: [
      {
        path: "orders/new",
        name: "manager.orders.new",
        component: NewOrders
      },
      {
        path: "orders/confirmed",
        name: "manager.orders.confirmed",
        component: ConfirmedOrders
      },
      {
        path: "orders/in-delivery",
        name: "manager.orders.in_delivery",
        component: InDelivery
      },
      {
        path: "orders/assigned",
        name: "manager.orders.assigned",
        component: Assigned
      },
      {
        path: "orders/preorders",
        name: "manager.orders.preorders",
        component: Preorders
      },
      {
        path: "orders/finished",
        name: "manager.orders.finished",
        component: Finished
      },
      {
        path: "orders/canceled",
        name: "manager.orders.canceled",
        component: Canceled
      },
      {
        path: "orders/pickup",
        name: "manager.orders.pickup",
        component: Pickup
      },
      {
        path: "map",
        name: "manager.map",
        component: Map
      }
    ]
  }
];
