import isAccountant from "@/middlewares/is-accountant";

const AccountantLayout = () => import("@/views/layouts/Accountant");

const Restaurants = () =>
  import(
    /* webpackChunkName: "accountant-restaurants" */ "@/views/accountant/Restaurants"
  );
const Dishes = () =>
  import(
    /* webpackChunkName: "accountant-restaurants" */ "@/views/accountant/restaurants/Dishes.vue"
  );
const StatisticsRestaurants = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Restaurants.vue"
  );
const StatisticsPayRestaurants = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/payRestaurants.vue"
  );
const StatisticsGlobal = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Global.vue"
  );
const StatisticsDishesRest = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/DishesRest.vue"
  );
  
const StatisticsOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Orders.vue"
  );
const StatisticsCoupons = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Coupons.vue"
  );
const StatisticsBalances = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Balances.vue"
  );
const StatisticsUserBalances = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/userBalances.vue"
  );
const StatisticsUserBalancesOnDate = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/userBalancesOnDate.vue"
  );
const StatisticsTips = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Tips.vue"
  );
const StatisticsAmex = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Amex.vue"
  );
const StatisticsPackageBalances = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/PackageBalances.vue"
  );
const StatisticsAccounting = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Accounting.vue"
  );
const StatisticsPurchases = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Purchases.vue"
  );
const StatisticsPurchasedOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/PurchasedOrders.vue"
  );
const StatisticsDeliveredOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/DeliveredOrders.vue"
  );
const StatisticsCancelledOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/CancelledOrders.vue"
  );

export default [
  {
    path: "/accountant",
    name: "accountant",
    component: AccountantLayout,
    redirect: { name: "accountant.statistics.delivery" },
    meta: {
      breadcrumb: "accountant.title",
      middleware: [isAccountant]
    },
    children: [
      {
        path: "restaurants",
        meta: {
          breadcrumb: "admin.restaurants.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "accountant.restaurants",
            component: Restaurants,
            meta: {
              breadcrumb: "admin.restaurants.title"
            }
          },

          // Sections
          {
            path: ":restaurant/dishes",
            meta: {
              breadcrumb: "admin.restaurants.sections.dishes.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "accountant.restaurants.dishes",
                component: Dishes,
                meta: {
                  breadcrumb: "admin.restaurants.sections.dishes.title"
                }
              }
            ]
          }
        ]
      },
      {
        path: "statistics/general",
        meta: {
          breadcrumb: "management.statistics.generals"
        },
        name: "accountant.statistics.general",
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "coupons",
            name: "accountant.statistics.coupons",
            component: StatisticsCoupons,
            meta: {
              breadcrumb: "management.statistics.coupons.title"
            }
          },
          {
            path: "balances",
            name: "accountant.statistics.balance",
            component: StatisticsBalances,
            meta: {
              breadcrumb: "management.statistics.balances.title"
            }
          },
          {
            path: "user_balances",
            name: "accountant.statistics.user_balance",
            component: StatisticsUserBalances,
            meta: {
              breadcrumb: "management.statistics.user_balances.title"
            }
          },
          {
            path: "balance_on_date",
            name: "accountant.statistics.balance_on_date",
            component: StatisticsUserBalancesOnDate,
            meta: {
              breadcrumb: "management.statistics.user_balances_on_date.title"
            }
          },
          {
            path: "payrestaurants",
            name: "accountant.statistics.payrestaurants",
            component: StatisticsPayRestaurants,
            meta: {
              breadcrumb: "management.statistics.payRestaurants.title"
            }
          },
          {
            path: "global_stat",
            name: "accountant.statistics.global_stat",
            component: StatisticsGlobal,
            meta: {
              breadcrumb: "management.statistics.global.title"
            }
          },
          {
            path: "dishes_rest",
            name: "accountant.statistics.dishes_rest",
            component: StatisticsDishesRest,
            meta: {
              breadcrumb: "management.statistics.dishes_rest.title"
            }
          }
        ]
      },
      {
        path: "statistics/delivery",
        name: "accountant.statistics.delivery",
        redirect: { name: "accountant.statistics.delivery.restaurants" },
        meta: {
          breadcrumb: "management.statistics.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "restaurants",
            name: "accountant.statistics.delivery.restaurants",
            component: StatisticsRestaurants,
            meta: {
              breadcrumb: "management.statistics.restaurants.title"
            }
          },
          {
            path: "orders",
            name: "accountant.statistics.delivery.orders",
            component: StatisticsOrders,
            meta: {
              breadcrumb: "management.statistics.orders.title"
            }
          },
          {
            path: "tips",
            name: "accountant.statistics.delivery.tips",
            component: StatisticsTips,
            meta: {
              breadcrumb: "management.statistics.tips.title"
            }
          },
          {
            path: "amex",
            name: "accountant.statistics.delivery.amex",
            component: StatisticsAmex,
            meta: {
              breadcrumb: "management.statistics.amex.title"
            }
          },
          {
            path: "package-balances",
            name: "accountant.statistics.delivery.package_balances",
            component: StatisticsPackageBalances,
            meta: {
              breadcrumb: "management.statistics.package_balances.title"
            }
          },
          {
            path: "accounting",
            name: "accountant.statistics.delivery.accounting",
            component: StatisticsAccounting,
            meta: {
              breadcrumb: "management.statistics.accounting.title"
            }
          },
          {
            path: "purchases",
            name: "accountant.statistics.delivery.purchases",
            component: StatisticsPurchases,
            meta: {
              breadcrumb: "management.statistics.purchases.title"
            }
          },
          {
            path: "purchased-orders",
            name: "accountant.statistics.delivery.purchased_orders",
            component: StatisticsPurchasedOrders,
            meta: {
              breadcrumb: "management.statistics.purchased_orders.title"
            }
          },
          {
            path: "delivered-orders",
            name: "accountant.statistics.delivery.delivered_orders",
            component: StatisticsDeliveredOrders,
            meta: {
              breadcrumb: "management.statistics.delivered_orders.title"
            }
          },
          {
            path: "cancelled-orders",
            name: "accountant.statistics.delivery.cancelled_orders",
            component: StatisticsCancelledOrders,
            meta: {
              breadcrumb: "management.statistics.cancelled_orders.title"
            }
          }
        ]
      },
      {
        path: "statistics/pickup",
        name: "accountant.statistics.pickup",
        redirect: { name: "accountant.statistics.pickup.restaurants" },
        meta: {
          breadcrumb: "management.statistics.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "restaurants",
            name: "accountant.statistics.pickup.restaurants",
            component: StatisticsRestaurants,
            meta: {
              breadcrumb: "management.statistics.restaurants.title",
              pickup: true
            }
          },
          {
            path: "orders",
            name: "accountant.statistics.pickup.orders",
            component: StatisticsOrders,
            meta: {
              breadcrumb: "management.statistics.orders.title",
              pickup: true
            }
          },
          {
            path: "tips",
            name: "accountant.statistics.pickup.tips",
            component: StatisticsTips,
            meta: {
              breadcrumb: "management.statistics.tips.title",
              pickup: true
            }
          },
          {
            path: "amex",
            name: "accountant.statistics.pickup.amex",
            component: StatisticsAmex,
            meta: {
              breadcrumb: "management.statistics.amex.title",
              pickup: true
            }
          },
          {
            path: "package-balances",
            name: "accountant.statistics.pickup.package_balances",
            component: StatisticsPackageBalances,
            meta: {
              breadcrumb: "management.statistics.package_balances.title",
              pickup: true
            }
          },
          {
            path: "accounting",
            name: "accountant.statistics.pickup.accounting",
            component: StatisticsAccounting,
            meta: {
              breadcrumb: "management.statistics.accounting.title",
              pickup: true
            }
          },
          {
            path: "purchases",
            name: "accountant.statistics.pickup.purchases",
            component: StatisticsPurchases,
            meta: {
              breadcrumb: "management.statistics.purchases.title",
              pickup: true
            }
          },
          {
            path: "purchased-orders",
            name: "accountant.statistics.pickup.purchased_orders",
            component: StatisticsPurchasedOrders,
            meta: {
              breadcrumb: "management.statistics.purchased_orders.title",
              pickup: true
            }
          },
          {
            path: "delivered-orders",
            name: "accountant.statistics.pickup.delivered_orders",
            component: StatisticsDeliveredOrders,
            meta: {
              breadcrumb: "management.statistics.delivered_orders.title",
              pickup: true
            }
          },
          {
            path: "cancelled-orders",
            name: "accountant.statistics.pickup.cancelled_orders",
            component: StatisticsCancelledOrders,
            meta: {
              breadcrumb: "management.statistics.cancelled_orders.title",
              pickup: true
            }
          }
        ]
      }
    ]
  }
];
